export default {
    invoicing: "Facturation",
    invoice: "Facture",
    invoices: "Factures",
    risk0: "Faible",
    risk1: "Moyen",
    risk2: "Élevé",
    his_date_col: "Date",
    his_periode_col: "Période",
    his_charge_vendue_col: "Charge vendue",
    his_charge_consommee_col: "Charge consommée",
    his_raf_col: "RAF",
    his_risque_col: "Risque",
    his_actions_col: "Actions",
    collaborator_col: "Collaborateur",
    ca_produit_col: "Ca produit",
    cout_consomme_col: "Cout engagé",
    cout_actualise_col: "Cout actualisé",
    ca_facture_col: "Ca facturé",
    charge_consommee_col: "Ch consommée",
    charge_produite_col: "Ch produite",
    charge_actualisee_col: "Ch actualisée",
    financial_monitoring_title: "Suivi financier",
    financial_monitoring_snapshots_title: "Historique du suivi financier",
    CA_produit:"CA produit",
    cout_consommee:"Cout consommé",
    cout_projet_actualise:"Cout actualisé",
    ca_facture:"CA facturé",
    cava_mensuel: "CAVA Mensuel",
    cava_cumule: "CAVA Cumulé",
    mbth_mensuel_especes: "MBth. € mensuel",
    mbth_cumule_especes: "MBth. € cumulé",
    mbth_mensuel_pourcentage: "MBth. % mensuel",
    mbth_cumule_pourcentage: "MBth. % cumulé",
    reste_col: "Reste",
    reste: "Reste",
    cost_profiles_history:"Historique des profils de coûts",
    cost_profile:"Profil de coûts",
    cost_profiles: "Profils de coûts",
    starting_Date:"Date de début",
    ending_Date:"Date de fin",
    no_cost_profile_found:"Aucun profil de coûts trouvé",
    tjm_Plancher: "TJM PLANCHER",
    costs_management: "Gestion des coûts",
    sale_orders: "Commandes",
    amountExclTaxes_col: "Montant",
    add_sale_order: "Ajouter une commande",
    sale_order_name_col: "Nom",
    purchase_cost_col: "Coût d'achat",
    sold_work_load: "Charge vendue",
    sale_nature_search_col: "Nature de vente",
    sale_nature_col: "NATURE DE VENTE",
    sale_type_search_col: "Type de vente",
    sale_type_col: "TYPE DE VENTE",
    closed_col: "Fermée",
    code_col: "Code",
    INITIAL : "Initiale",
    MODIFICATION : "Avenant",
    no_sale_type_found: "Aucun type de vente trouvé",
    no_sale_nature_found: "Aucune nature de vente trouvée",
    delete_sale_order_modal: "Vous êtes sur le point de supprimer la commande ",
    have_echanciers : " qui a déjà des échéanciers",
    delete_sale_orders_selection_modal: "Cette action entraîne la suppression multiple de",
    delete_sale_orders_with_echanciers_selection_modal: "Vous êtes sur le point de supprimer des commandes qui sont associées à des échéanciers",
    echeancier_name_col: "Nom",
    echeancier_sale_order_name_col: "Commande de vente",
    echeancier_code_col: "Code",
    echeancier_description_col: "Description",
    echaencier_estimatedExclTaxesAmount_col: "Montant prévisionnel HT",
    echaencier_actualAmount_col: "Montant réel",
    echeancier_estimatedInvoiceDate_col: "Date prévisionnelle de facturation",
    echeancier_estimatedInvoice_text: " prévisionnelle de facturation",
    echeancier_date_text: "Date",
    echeancier_actualInvoiceDate_col: "Date réelle de facturation",
    echeancier_estimatedWorkload_col: "Charge prévisionnelle",
    echeancier_actualWorkload_col: "Charge réelle",
    echeancier_invoiceNumber_col: "Numéro de facture",
    echeancier_statu_col: "Statut",
    no_statu_found: "Aucun statu d'échéancier trouvé",
    add_echeancier: "Ajouter un échéancier",
    echeanciers: "Échéanciers",
    delete_echancier_modal: "Vous êtes sur le point de supprimer l'échéancier ",
    this_field_is_required_form_error: "le champ est obligatoire !",
    required_field: "Ce champ est obligatoire",
    ESTIMATED: "Prévisionnelle",
    ISSUED: "Émise",
    DELAYED: "Retardée",
    CANCELED: "Annulée",
    created_at:'Date de création',
    cout_consomme:'Coût engagé ',
    cout_actualise:'Coût actualisé',
    show_history: 'Voir l\'historique',
    tjm_projet: 'TJM vendu',
    marge: 'Marge',
    marge_actualisee: 'Marge actualisée',
    CA_vendu: 'CA vendu',
    ca_produit: 'CA produit',
    marge_mensule_percent: 'Marge brute %',
    marge_mensule: 'Marge brute {{devise}}',
    CA_vendu_info_bulle: "Le chiffre d'affaires total généré par les ventes réalisées pour ce projet",
    tjm_projet_info_bulle: "Le tarif journalier moyen facturé pour les prestations effectuées dans le cadre de ce projet",
    ca_produit_info_bulle: "Le chiffre d'affaires total prévu pour ce projet, calculé en fonction des prestations déjà réalisées et des futures prestations planifiées",
    cout_actualise_info_bulle: "Le coût total réellement supporté pour ce projet, prenant en compte les dépenses engagées jusqu'à la date actuelle",
    cout_consomme_info_bulle: "Le montant total des coûts encourus jusqu'à présent pour ce projet, y compris les salaires, les frais généraux et les dépenses associées",
    ca_facture_info_bulle: "Le montant total facturé aux clients pour les prestations effectuées dans le cadre de ce projet",
    marge_mensule_percent_info_bulle: "Le pourcentage de marge brute réalisé sur ce projet, calculé en soustrayant les coûts engagés du CA produit et en le divisant par le CA produit.",
    marge_mensule_info_bulle: "Le montant de la marge brute réalisée sur ce projet, calculé en soustrayant les coûts engagés du CA produit",
    updated_by: "Modifié par",
    updated_at: "Date de modification",
    agency: "Agence",
    job: "Poste",
    no_agency_found: "Aucune agence trouvée",
    active: "Collaborateurs actifs",
    sale_order: "Commande",
    no_job_found: "Aucun Poste trouvé",
    without_profile: "Collaborateurs sans profil",
    periode: "Période",
    start_date_is_greater: "La date de début est supérieure à la date d'aujourd'hui",
    cost_history_gap: "Il y a un écart entre les dates des profils de coûts"
};