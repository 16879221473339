import {combineReducers} from "redux";
import indicatorsReducer from './indicators';
import projectsReducer from './projects';
import collaboratorsReducer from './collaborators';
import snapshotsReducer from './snapshots';
import rtReducer from './rt';
import collaboratorCostHistoryReducer from './collaboratorCostHistory';
import collaboratorMonthlyCostReducer from './collaboratorMonthlyCost';
import saleOrderReducer from './saleOrders';
import echanciers from './echancier';

export default combineReducers({
    indicators: indicatorsReducer,
    projects: projectsReducer,
    collaborators: collaboratorsReducer,
    snapshots: snapshotsReducer,
    saleOrders: saleOrderReducer,
    echanciers: echanciers,
    rt: rtReducer,
    collaboratorCostHistory: collaboratorCostHistoryReducer,
    collaboratorMonthlyCost: collaboratorMonthlyCostReducer
});
